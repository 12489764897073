import { useEffect, useState, useCallback } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { PATH_DASHBOARD } from "../../routes/paths";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import {
  Stack,
  Grid,
  Box,
  CardContent,
  TextField,
  Chip,
  Card,
  CardHeader,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TableNoData } from "../../components/table";
import Iconify from "../../components/iconify";
import FormProvider, {
  RHFTextField,
  RHFAutocomplete,
  RHFUploadAvatar,
} from "../../components/hook-form";
import {
  getDetailList,
  getCountryListAPI,
  subSectApi,
  getStateListAPI,
  addBiodata,
  bioDataDetail,
  getStaffNumberAPI,
  addSubSectApi,
  getNotes,
} from "src/service";
import { useSnackbar } from "../../components/snackbar";
import { BioDataCard } from "../../sections/@dashboard/user/cards";
import { fData } from "../../utils/formatNumber";
import Label from "../../components/label";
import BioDataUpdate from "./BioDataUpdate";

const disability = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Some Medical Problem" },
  { id: 3, name: "Physically Challenged" },
];

const maritalStatus = [
  { id: 1, name: "Unmarried" },
  { id: 2, name: "Widow/widower" },
  { id: 3, name: "Divorce" },
  { id: 4, name: "Awiting divorce" },
];

const manlik = [
  { id: 1, name: "YES" },
  { id: 2, name: "NO" },
  { id: 3, name: "DON`T KNOW" },
  { id: 4, name: "Anshik" },
];

const family = [
  { id: 1, name: "Poor" },
  { id: 2, name: "Lower Middle Class" },
  { id: 3, name: "Middle Class" },
  { id: 4, name: "Upper Middle Class" },
  { id: 5, name: "Rich/Affluent" },
];

export default function BioDataEntryForms() {
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [subsect, setSubsect] = useState([]);
  const [Detail, setDetail] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [arr, setArr] = useState([{ number: "" }]);
  const [year, setYear] = useState([]);
  const [biodataDetail, setBioDataDetail] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filterMob, setFilter] = useState();
  const [bioImage, setImage] = useState([]);
  const [filterDetail, setFilterDetail] = useState({});
  const [show, setShow] = useState(false);
  const [addHide, setAddHide] = useState(false);
  const [showSubSect, setShowSubSect] = useState(false);
  const [staffNumber, setStaffNumber] = useState([]);
  const [subSectWrite, setSubSectWrite] = useState("");
  const [sect, setSect] = useState("");
  const [bioNotes, setNotes] = useState([]);
  const [showUpdate, setUpdate] = useState({ show: true });
  const [clear, setClear] = useState("");

  useEffect(() => {
    getCountryList();
    getStateList("India");
    getDetail();
    getStaffNumber();
    birthYear();
  }, []);

  useEffect(() => {
    getBiodataDetail();
  }, [filterMob]);

  const getStaffNumber = async () => {
    let res = await getStaffNumberAPI();
    setStaffNumber(
      res?.data?.data.map((val) => ({ label: val.number, value: val.number }))
    );
  };

  const getBiodataDetail = async () => {
    var obj = {};
    let arrMob = [];
    arr?.map((i) => {
      arrMob.push(i.number);
    });
    if (filterMob) obj.mobile = arrMob;
    if (!obj?.mobile) obj.created_at = "today";
    let res = await bioDataDetail(0, 25, obj);
    setBioDataDetail(res?.data?.data?.users);
    setTotalUsers(res?.data?.data?.total);
    if (res?.data?.data?.users?.length === 0) setIsNotFound(true);
    else setIsNotFound(false);
  };

  const birthYear = () => {
    let currentYear = new Date().getFullYear();
    let range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    setYear(range(currentYear - 18, currentYear - 79, -1));
  };

  const addInput = () => {
    setArr([...arr, { number: "" }]);
  };

  const handleRemoveItem = (index) => {
    const list = [...arr];
    list.splice(index, 1);
    setArr(list);
  };

  const addNumber = (ind, val) => {
    const newArray = arr.map((item, i) => {
      if (ind === i) {
        return { ...item, number: val };
      } else {
        return item;
      }
    });
    setArr(newArray);
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    gender: Yup.string().required("Gender is required"),
  });

  const methods = useForm({ resolver: yupResolver(LoginSchema) });

  const {
    reset,
    watch,
    getValues,
    setError,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const getCountryList = async () => {
    let res = await getCountryListAPI();
    setCountryList(res?.data?.data);
  };

  const getDetail = async () => {
    let res = await getDetailList();
    setDetail(res?.data?.data);
  };

  const watchCountry = watch("country");
  useEffect(() => {
    let v = getValues("country");
    if (v) getStateList(v);
    else setValue("country", "India");
  }, [watchCountry, getValues]);

  const getStateList = async (value) => {
    var filterDetails = await getDetailList();
    let res = await getStateListAPI(value);
    setFilterDetail({
      ...filterDetails?.data?.data,
      stateList: res?.data.data,
    });
    setStateList(res?.data?.data);
    let res1 = await getNotes();
    setNotes(res1?.data?.data);
  };

  const watchSect = watch("sect");
  useEffect(() => {
    let v = getValues("sect");
    setSect(v);
    if (v) selectSubSect(v);
  }, [watchSect, getValues]);

  const selectSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubsect(res?.data?.data);
      setShow(true);
    }
  };

  const onSubmit = async (data) => {
    let arrMob = [];
    var mobiles = [];
    arr?.map((i) => {
      arrMob.push(i.number);
      var obj = {
        mobile: i.number,
        is_whatsapp: false,
        is_call_done: false,
        time: null,
      };
      mobiles.push(obj);
    });
    var formData = new FormData();
    if (bioImage) formData.append("images", bioImage);
    if (data?.name) formData.append("name", data?.name);
    if (arrMob.length > 0) formData.append("mobile", arrMob.join(","));
    if (mobiles.length > 0) formData.append("mobiles", JSON.stringify(mobiles));
    if (data?.gender) formData.append("gender", data?.gender);
    if (data?.employed_in) formData.append("employed_in", data?.employed_in);
    if (data?.education)
      formData.append("education", data?.education.join(","));
    if (data?.country) formData.append("country", data?.country);
    if (data?.sect) formData.append("sect", data?.sect);
    if (data?.sub_sect) formData.append("sub_sect", data?.sub_sect);
    if (data?.manglik) formData.append("manglik", data?.manglik);
    if (data?.state) formData.append("state", data?.state);
    if (data?.marital_status)
      formData.append("marital_status", data?.marital_status);
    if (data?.dob) formData.append("dob", data?.dob);
    if (data?.physical_status) {
      formData.append("physical_status", data?.physical_status);
    } else {
      formData.append("physical_status", "Normal");
    }
    try {
      let res = await addBiodata(formData);
      // setClear();
      // reset({
      //     mobile: '',
      //     name: '',
      // })
      if (res?.data?.status) enqueueSnackbar(res?.data?.message);
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
        getBiodataDetail();
        window.location.reload();
      } else enqueueSnackbar(res?.data?.message, { variant: "error" });
    } catch (error) {
      console.error(error);
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
  };

  const addSubSect = async () => {
    var data = {};
    if (subSectWrite) data.englishName = subSectWrite;
    data.sect = sect;
    var res = await addSubSectApi(data);
    if (res.data.status) {
      selectSubSect(sect);
      setValue("sub_sect", subSectWrite);
      enqueueSnackbar(res.data.message);
      setAddHide(true);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (file) {
        setImage(file);
        setValue("avatarUrl", newFile);
      }
    },
    [setValue]
  );

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>Bio Data Entry Forms : Master Admin</title>
      </Helmet>
      {!showUpdate.show && (
        <CardHeader
          title={
            <Typography sx={{ mt: -2, mb: 2 }} variant="h4">
              Bio Data Update
            </Typography>
          }
        />
      )}

      {!showUpdate.show && (
        <BioDataUpdate
          showUpdate={showUpdate}
          getBiodataDetail={getBiodataDetail}
          setUpdate={setUpdate}
        />
      )}

      {showUpdate.show && (
        <>
          {" "}
          <Card>
            <CardHeader
              title={
                <Typography sx={{ px: 1, mt: -2, mb: 2 }} variant="h4">
                  Bio Data Entry Forms
                </Typography>
              }
            />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={1} sx={{ px: 3 }}>
                <Stack direction="row" spacing={2} sx={{ px: 1 }}>
                  <Grid container spacing={2}>
                    {arr.map((item, index) => {
                      return (
                        <Grid key={index} item lg={2} md={2} xs={6} sm={6}>
                          <Stack>
                            <RHFTextField
                              value={arr[index].number}
                              name="mobile"
                              label="Mobile Number"
                              size="small"
                              type="number"
                              onChange={(e) => {
                                addNumber(index, e.target.value);
                                if (e.target.value.length > 9) {
                                  setFilter(e.target.value);
                                }
                              }}
                            />
                            <Stack alignItems="flex-end">
                              {index !== 0 && (
                                <Iconify
                                  onClick={() => {
                                    handleRemoveItem(index);
                                  }}
                                  icon="mdi:cancel-circle-outline"
                                />
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                      );
                    })}

                    <Stack sx={{ mt: 3, ml: 2 }}>
                      <Label>{totalUsers} </Label>
                    </Stack>
                  </Grid>
                </Stack>
                <Grid item lg={3} md={3} xs={6} sm={6} sx={{ px: 1 }}>
                  <Button
                    size="small"
                    onClick={addInput}
                    sx={{
                      bgcolor: "text.primary",
                      width: 100,
                      justifyContent: "center",
                      typography: "body2",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                      "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "common.white"
                            : "grey.800",
                      },
                    }}
                  >
                    Add Number
                  </Button>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFTextField name="name" label="Name" size="small" />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="gender"
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) =>
                        setValue("gender", newValue)
                      }
                      options={[
                        { id: 1, name: "Male" },
                        { id: 2, name: "Female" },
                      ]?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option?.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Gender" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="employed_in"
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) =>
                        setValue("employed_in", newValue)
                      }
                      options={Detail?.employedIn?.english?.map(
                        (option) => option?.label
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Profession" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="education"
                      multiple
                      freeSolo
                      size="small"
                      onChange={(event, newValue) =>
                        setValue("education", newValue)
                      }
                      options={Detail?.education?.map(
                        (option) => option?.name_english
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Education" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="sect"
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) => setValue("sect", newValue)}
                      options={[
                        { id: 1, name: "Digambar" },
                        { id: 2, name: "Shwetambar" },
                      ]?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option?.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Sect" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <Stack alignItems="center">
                      <RHFAutocomplete
                        name="sub_sect"
                        fullWidth
                        size="small"
                        disableListWrap
                        value={clear}
                        onChange={(e, newValue) => {
                          setValue("sub_sect", newValue);
                          setAddHide(true);
                        }}
                        options={subsect?.map((option) => option?.english)}
                        groupBy={(option) => option[0].toUpperCase()}
                        renderTags={(value, getTagProps) =>
                          value?.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              key={option}
                              size="small"
                              label={option}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField label="Sub Sect" {...params} />
                        )}
                      />
                      {show && !addHide && (
                        <Button
                          variant="contained"
                          color="inherit"
                          size="small"
                          sx={{ mt: 1, mb: 1 }}
                          onClick={() => {
                            setShowSubSect(!showSubSect);
                          }}
                        >
                          Add Sub Sect1
                        </Button>
                      )}{" "}
                    </Stack>

                    {showSubSect && !addHide && (
                      <Stack spacing={1}>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            item
                            lg={9}
                            md={9}
                            sm={12}
                            xs={12}
                            direction="row"
                          >
                            <RHFTextField
                              name="sub_sect"
                              label="Write Here"
                              size="small"
                              onChange={(event) => {
                                setSubSectWrite(event.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item lg={3} md={3} sm={3} xs={4}>
                            <Button
                              variant="contained"
                              color="inherit"
                              size="small"
                              onClick={() =>
                                addSubSect("sect", "sub_sect", "hindiName")
                              }
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Stack>
                    )}
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="manglik"
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) =>
                        setValue("manglik", newValue)
                      }
                      options={manlik?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Manglik" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="country"
                      freeSolo
                      size="small"
                      defaultValue={"India"}
                      onChange={(event, newValue) =>
                        setValue("country", newValue)
                      }
                      options={countryList?.map(
                        (option) => option?.name_english
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Country" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="state"
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) =>
                        setValue("state", newValue)
                      }
                      options={StateList?.map((option) => option?.english)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option?.english}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select State" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="marital_status"
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) =>
                        setValue("marital_status", newValue)
                      }
                      options={maritalStatus?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Marital status" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="physical_status"
                      freeSolo
                      size="small"
                      defaultValue={"Normal"}
                      onChange={(event, newValue) =>
                        setValue("physical_status", newValue)
                      }
                      options={disability?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Disability" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name="dob"
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) => setValue("dob", newValue)}
                      options={year?.map((option) => option)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Birth Year" {...params} />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={3} xs={4}>
                    <RHFAutocomplete
                      name=""
                      freeSolo
                      size="small"
                      value={clear}
                      onChange={(event, newValue) => setValue("", newValue)}
                      options={family?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Family Status" {...params} />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="Flex-start"
                >
                  <Grid item lg={3} md={3} sm={4} xs={4}>
                    <Stack alignItems="center">
                      <RHFUploadAvatar
                        name="avatarUrl"
                        maxSize={3145728}
                        onDrop={handleDrop}
                        sx={{ height: 150 }}
                        helperText={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 2,
                              mx: "auto",
                              display: "block",
                              textAlign: "center",
                              color: "text.secondary",
                            }}
                          >
                            max size of {fData(2000000)}
                          </Typography>
                        }
                      />
                    </Stack>
                  </Grid>

                  <Grid item lg={3} md={3} sm={4} xs={4}>
                    <div style={{ textAlign: "center" }}>
                      <LoadingButton
                        color="inherit"
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{
                          mx: 4,
                          bgcolor: "text.primary",
                          width: "50%",
                          justifyContent: "center",
                          typography: "body1",
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? "common.white"
                              : "grey.800",
                          "&:hover": {
                            bgcolor: "text.primary",
                            color: (theme) =>
                              theme.palette.mode === "light"
                                ? "common.white"
                                : "grey.800",
                          },
                        }}
                      >
                        Add
                      </LoadingButton>
                    </div>
                  </Grid>
                </Grid>
              </Stack>
            </FormProvider>
          </Card>
        </>
      )}
      <CardContent>
        <Box
          gap={4}
          display="grid"
          gridTemplateColumns={{
            lg: "repeat(3, 1fr)",
            md: "repeat(3, 1fr)",
            sm: "repeat(1, 1fr)",
            xs: "repeat(1, 1fr)",
          }}
          sx={{ pd: 3 }}
        >
          {biodataDetail?.map((bioData) => (
            <BioDataCard
              bioData={bioData}
              showUpdate={showUpdate}
              setUpdate={setUpdate}
              staffNumber={staffNumber}
              getBiodataDetail={getBiodataDetail}
              bioNotes={bioNotes}
              filterDetail={filterDetail}
              goToTop={goToTop}
            />
          ))}
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center">
          <TableNoData isNotFound={isNotFound} />
        </Box>
      </CardContent>
    </>
  );
}

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Checkbox,
  Switch,
  Table,
  TableBody,
  TableRow,
  Dialog,
  FormControlLabel,
  FormGroup,
  CardHeader,
  Tooltip,
  DialogContent,
  Grid,
  Badge,
  TableCell,
  IconButton,
  Typography,
  MenuItem,
  TableContainer,
  Box,
  DialogTitle,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, Link as RouterLink, useNavigate } from "react-router-dom";
import Label from "../../../../components/label";
import Iconify from "../../../../components/iconify";
import {
  createAccessForStaff,
  createDoubleTickKeys,
  createSectionAccessHistoryApi,
  deleteUser,
  getStaffApi,
  getStaffShareToData,
  staffNameApi,
  updateAccessForStaff,
  updatedoubleTickAccess,
  updateMasking,
  updateUser,
} from "src/service";
import { useSnackbar } from "../../../../components/snackbar";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import FormProvider, { RHFTextField } from "../../../../components/hook-form";
import { useForm } from "react-hook-form";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from "src/components/table";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
import { useAuthContext } from "src/auth/useAuthContext";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import moment from "moment";
import constant, {
  currentAppVersion,
  currentStaffAppVersion,
} from "src/Constant";
const sections = [
  { label: "Online Users", value: "online", checked: false },
  { label: "Offline Users", value: "offline", checked: false },
  { label: "Biodata Users", value: "biodata", checked: false },
  { label: "Padadhikari", value: "padadhikari", checked: false },
  {
    label: "Padadhikari - Jai Jinendra Message",
    value: "padadhikari_jai_jinendra_message",
    checked: false,
  },
  { label: "Padadhikari Users", value: "padadhikari_users", checked: false },
  {
    label: "Padadhikari Online Users",
    value: "padadhikari_online_users",
    checked: false,
  },
  {
    label: "Selected Padadhikari",
    value: "selected_padadhikari",
    checked: false,
  },
  { label: "Response Data", value: "response_data", checked: false },
  { label: "WhatsApp Group", value: "whatsapp_group", checked: false },
  // { label: 'Whatsapp response', value: 'whatsapp_response', checked: false },
  { label: "Incoming Call", value: "incoming_call", checked: false },
  { label: "Hindu Online", value: "hindu_online", checked: false },
  {
    label: "Hindu Whatsapp response",
    value: "hindu_whatsapp_response",
    checked: false,
  },
  {
    label: "Hindu Incoming call",
    value: "hindu_incoming_call",
    checked: false,
  },
  { label: "Group Link", value: "group_link", checked: false },
  {
    label: "Share To Pending Task",
    value: "share_to_pending_task",
    checked: false,
  },
  // { label: 'Excel History', value: 'Excel History', checked: false }
];

const responseData = [
  { label: "Whatsapp Response", value: "whatsapp_response", checked: false },
  { label: "Whatsapp Group Services", value: "group_service", checked: false },
  { label: "Paid Promotion", value: "paid_promotion", checked: false },
  { label: "Social Media", value: "social_media", checked: false },
];

const specialSections = [
  { label: "Demo Profile Team", value: "Demo Profile Team", checked: false },
  { label: "Social Media Data", value: "Social Media Data", checked: false },
  { label: "TL Seniour / Sale", value: "TL Seniour / Sale", checked: false },
  {
    label: "TL Seniour / Problem",
    value: "TL Seniour / Problem",
    checked: false,
  },
  { label: "Helpline Number", value: "Helpline Number", checked: false },
  { label: "Approval", value: "Approval", checked: false },
  { label: "Registration", value: "Registration", checked: false },
  { label: "Verification", value: "Verification", checked: false },
];

const TABLE_HEAD = [
  { id: "id", label: "Id" },
  { id: "name", label: "Name", align: "left" },
  { id: "section", label: "Section", align: "left" },
  { id: "" },
];
const sections2 = [
  { label: "Demo Profile Team", value: "" },
  { label: "Padadhikari Team", value: "" },
  { label: "TL Seniour / Sale", value: "" },
  { label: "TL Seniour / Problem", value: "" },
  { label: "Helpline Number", value: "" },
];

StaffTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function StaffTableRow({
  row,
  selected,
  roles,
  type,
  showUpdate,
  getStaff,
  setShowUpdate,
  showPassword,
  setShowPassword,
  goToTop,
  staffData,
  setStaffData,
  setStaff,
  staff,
  index,
  staffName,
}) {
  const {
    dense,
    page,
    rowsPerPage,
    orderBy,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onChangePageButton,
    onSort,
  } = useTable();
  const contxt = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();
  const [newRow, setNewRow] = useState(row);
  const [staffPermissionModal, setStaffPermissionModal] = useState(false);
  const [staffPermissionModalTest, setStaffPermissionModalTest] =
    useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openRole, setIsOpenRole] = useState(false);
  const [staffPermission, setStaffPermission] = useState(null);
  const [specialPermission, setSpecialPermission] = useState([]);
  const [rowId, setRowId] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);
  const [isOpenAssign, setIsOpenAssign] = useState(false);
  const [specialStaff, setSpecialStaff] = useState([]);
  const [assignData, setAssignData] = useState(null);
  const [roleData, setRoles] = useState();
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openDoubleTick, setOpenDoubleTick] = useState(false);
  const [rAssignRowData, setRAssignRowData] = useState();
  const [doubleTickNumber, setDoubleTickNumber] = useState("");
  const [staffAppPermissions, setStaffAppPermissions] = useState([]);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [doubletickUpdate, setDoubletickUpdate] = useState({});
  const navigate = useNavigate();
  const [selectedResponseData, setSelectedResponseData] =
    useState(responseData);

  useEffect(() => {
    if (openRole) {
      setSelectedRoleIds(
        row?.role_assigned_to_staff !== null ? row?.role_assigned_to_staff : []
      );
      setRoles({ id: staffData.role_id });
    }
  }, [openRole]);

  // useEffect(() => {
  //   getStaffName();
  // }, [addTeamModal]);

  const handleCopy = () => {
    enqueueSnackbar("Copied!");
    copy(row.id);
  };

  const handleClose = () => {
    setStaffPermissionModal(!staffPermissionModal);
  };
  const handleStaffAppPermissionModal = () => {
    setStaffAppPermissions(row?.sectionAccessStaff[0]?.access_section);
    setStaffPermissionModalTest(!staffPermissionModalTest);
  };
  const handleCloseForTeam = () => {
    setAddTeamModal(!addTeamModal);
  };

  const handleCloseForSpecial = () => {
    setIsOpen(!isOpen);
  };

  const maskNumber = (phoneNumber) => {
    const number = contxt?.maskPhoneNumber(phoneNumber);
    if (number) return number;
  };

  const handleCloseForRole = () => {
    setIsOpenRole(!openRole);
  };

  const handleRoleToggle = (roleId) => {
    const isSelected = selectedRoleIds.includes(roleId);
    let newSelectedRoleIds;

    if (isSelected) {
      newSelectedRoleIds = selectedRoleIds.filter((id) => id !== roleId);
    } else {
      newSelectedRoleIds = [...selectedRoleIds, roleId];
    }
    setSelectedRoleIds(newSelectedRoleIds);
  };

  const handleCloseForAssign = () => {
    setIsOpenAssign(!isOpenAssign);
  };

  const handleOpenDelete = () => {
    setOpenDelete(!openDelete);
  };

  const handleOpenRemove = () => {
    setOpenRemove(!openRemove);
  };

  const handleOpenDoubleTick = () => {
    setOpenDoubleTick(!openDoubleTick);
  };

  const handleCloseResponseModal = () => {
    setResponseModalOpen(!responseModalOpen);
  };

  const handleOpenResponseModal = () => {
    const isResponseDataChecked = staffPermission?.find(
      (section) => section?.value === "response_data"
    )?.checked;
    setResponseModalOpen(isResponseDataChecked);
  };

  const LoginSchema = Yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    reset,
    setError,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const watchSepecialSection = watch("special_section");
  useEffect(() => {
    let v = getValues("special_section");
    if (v) getSepecialStaff(v);
  }, [watchSepecialSection, getValues]);

  const getSepecialStaff = async (data) => {
    var res = await getStaffApi(0, 50, { special_section: data });
    setSpecialStaff(res.data.data.users);
  };

  useEffect(() => {
    if (isOpenAssign) getShareToStaff();
  }, [isOpenAssign, page, rowsPerPage]);

  const getShareToStaff = async () => {
    try {
      const requestData = { id: rowId, public_view: 1 };
      const res = await getStaffShareToData(requestData, page, rowsPerPage);

      if (res?.data?.data) {
        setAssignData(res.data.data);
        setIsNotFound(res.data.data.length === 0);
      } else {
        setAssignData([]);
        setIsNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching staff share data:", error);
      setAssignData([]);
      setIsNotFound(true);
    }
  };
  const users = assignData?.users[0]?.assigned_staff || [];

  const userRows =
    Array.isArray(users) &&
    users.map((row, index) => {
      if (row) {
        return (
          <TableRow hover key={row.id}>
            <TableCell>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2" noWrap>
                  {row?.user_id}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2" noWrap>
                  {row?.name}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2" noWrap>
                  {row?.title || "-"}
                </Typography>
              </Stack>
            </TableCell>
            {/* <TableCell>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleOpenRemove();
                setRAssignRowData(row);
              }}
            >
              Remove
            </Button>
          </TableCell> */}
          </TableRow>
        );
      } else {
        console.warn(`Row ${index} is not defined:`, row);
        return null;
      }
    });

  const onStatus = async (data) => {
    var res = await updateUser(row.id, data);
    getStaff();
    if (res?.status) enqueueSnackbar(res?.data?.message);
  };

  const onDelete = async () => {
    var data = {};
    data.type = "permanent";
    var res = await deleteUser(row.id, data);
    if (res?.data?.status) {
      getStaff();
      if (res?.status) enqueueSnackbar(res?.data?.message);
    }
  };

  const onRoles = async (data) => {
    let obj = {
      // "role_id": roleData.id
      role_assigned_to_staff: selectedRoleIds,
    };
    const filteredRoles = roles
      .filter((role) => selectedRoleIds.includes(role.id))
      ?.map((role) => role?.role)
      ?.join(",");
    var res = await updateUser(row.id, obj);
    getStaff();
    if (res?.status) {
      enqueueSnackbar(res?.data?.message);
      let objV = {
        role_assigned: [
          {
            role_assigned: filteredRoles,
            updated_date_time: new Date().toISOString(),
            staff_id: contxt?.user?.id,
            staff_name: contxt?.user?.nameEnglish,
          },
        ],
      };
      await createSectionAccessHistoryApi(row.id, objV);
      handleCloseForRole();
    }
  };
  const handleStaffAppPermissionSections = async (data, id) => {
    if (row?.sectionAccessStaff?.length > 0) {
      handleStaffAppPermissionModal();
    } else {
      let obj = {
        id: id,
        last_update_history: [
          {
            last_update_by_id: contxt?.user?.id,
            last_update_by_name: contxt?.user?.nameEnglish,
            last_updated_time: new Date().toISOString(),
          },
        ],
      };
      const res = await createAccessForStaff(obj);
      if (res?.data?.status) {
        let temp = [...staff];
        temp[index].sectionAccessStaff = [res?.data?.data];
        setStaff(temp);
        handleStaffAppPermissionModal();
      }
      if (!res?.data?.status) {
        handleStaffAppPermissionModal();
      }
    }
  };

  const handleDoubleTick = async (data, id) => {
    setDoubletickUpdate(row?.DoubleTickAccess[0]);
    handleOpenDoubleTick();
  };

  const onSubmit = async () => {
    let obj = {
      permissions: [],
      id: staffData?.id,
    };
    staffPermission?.forEach((i) => {
      if (i.checked) {
        if (
          i?.value === "paid_promotion" ||
          i?.value === "social_media" ||
          i?.value === "whatsapp_group_service" ||
          i?.value === "whatsapp_response"
        ) {
          obj.response_data_status = true;
        }
        obj.permissions.push(i.value);
      }
    });
    obj.permissions = obj?.permissions?.join(",");
    try {
      var res = await updateUser(staffData?.id, obj);
      if (res?.status) {
        enqueueSnackbar(res?.data?.message);
        let objV = {
          permissions: [
            {
              permissions: obj.permissions,
              updated_date_time: new Date().toISOString(),
              staff_id: contxt?.user?.id,
              staff_name: contxt?.user?.nameEnglish,
            },
          ],
        };
        await createSectionAccessHistoryApi(row.id, objV);
      }
      getStaff();
      setStaffPermissionModal(!staffPermissionModal);
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", { ...error, message: error.message });
    }
  };

  const onSubmitStaffAppSection = async () => {
    try {
      let update_history = {
        last_update_by_id: contxt?.user?.id,
        last_update_by_name: contxt?.user?.nameEnglish,
        last_updated_time: new Date().toISOString(),
      };

      let tempData = [...row?.sectionAccessStaff[0]?.last_update_history];
      tempData.push(update_history);

      let obj = {};
      obj.users_id = row?.id;
      obj.last_update_history = tempData;
      obj.access_section = staffAppPermissions;

      const res = await updateAccessForStaff(obj);
      if (res?.data?.status) {
        setStaffPermissionModalTest(!staffPermissionModalTest);
        setStaffAppPermissions([]);
        enqueueSnackbar(res?.data?.message);
      } else {
        enqueueSnackbar(res?.data?.message, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitSpecial = async () => {
    let obj = { special_section: [], id: staffData?.id };
    specialPermission?.forEach((i) => {
      if (i.checked) obj.special_section.push(i.value);
    });
    obj.special_section = obj?.special_section?.join(",");
    try {
      var res = await updateUser(staffData?.id, obj);
      let objV = {
        special_section: [
          {
            special_section: obj.special_section,
            updated_date_time: new Date().toISOString(),
            staff_id: contxt?.user?.id,
            staff_name: contxt?.user?.nameEnglish,
          },
        ],
      };
      await createSectionAccessHistoryApi(row.id, objV);
      if (res?.status) enqueueSnackbar(res?.data?.message);
      getStaff();
      handleCloseForSpecial();
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", { ...error, message: error.message });
    }
  };

  const onSubmitAssign = async (data) => {
    var payload = [];
    var obj = {
      user_id: data?.special_staff?.id,
      name: data?.special_staff?.nameEnglish,
      title: data?.special_section,
    };
    if (staffData?.assigned_staff?.length > 0) {
      staffData?.assigned_staff.push(obj);
      payload = staffData?.assigned_staff;
    } else payload = [obj];
    try {
      var res = await updateUser(staffData?.id, { assigned_staff: payload });
      getShareToStaff();
      if (res?.status) enqueueSnackbar(res?.data?.message);
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", { ...error, message: error.message });
    }
  };
  // response data assign
  const onSubmitResponseAssign = async (data) => {
    var obj = {
      id: staffData?.id,
      response_data_section: selectedResponseData
        .filter((response) => response.checked)
        .map((response) => response.value)
        .join(", "),
    };
    try {
      var res = await updateUser(staffData?.id, obj);
      getShareToStaff();
      if (res?.status) enqueueSnackbar(res?.data?.message);
      handleCloseResponseModal();
    } catch (error) {
      reset();
    }
  };

  const handleMasking = async (id) => {
    const res = await updateMasking(id);
    if (res?.data?.status) {
      enqueueSnackbar(res?.data?.message);
      getStaff();
    }
  };
  const onDobleTickAssign = async (data) => {
    try {
      data.access_by_staff = contxt?.user?.id;
      data.access_to_staff = row?.id;
      if (
        data?.double_tick_crm_one_status ||
        data.double_tick_crm_one_status === "true"
      ) {
        data.staff_num_access_at_double_tick_one = `91${row?.StaffMobileNum[0]?.number_one}`;
      } else if (
        data?.double_tick_crm_two_status ||
        data.double_tick_crm_two_status === "true"
      ) {
        data.staff_num_access_at_double_tick_two = `91${row?.StaffMobileNum[0]?.number_one}`;
      } else if (
        data?.double_tick_crm_three_status ||
        data.double_tick_crm_three_status === "true"
      ) {
        data.staff_num_access_at_double_tick_three = `91${row?.StaffMobileNum[0]?.number_one}`;
      }
      data.double_tick_crm_one_num = "7583831008";
      data.double_tick_crm_two_num = "7583831009";
      data.double_tick_crm_three_num = "9516520150";
      var res = null;
      if (row?.DoubleTickAccess?.length === 0) {
        res = await createDoubleTickKeys(data);
      } else {
        res = await updatedoubleTickAccess(data);
      }
      if (res?.data?.status) {
        enqueueSnackbar(res?.data?.message);
      }
      getStaff();
      setDoubletickUpdate({});
    } catch (error) {
      console.log("error ::::", error);
      enqueueSnackbar(error?.errors, { variant: "error" });
    }
    // if (res?.status) enqueueSnackbar(res?.data?.message);
  };

  const onRemoveAssign = async () => {
    var tempData = [...staffData?.assigned_staff];
    const deleteRow = tempData.filter(
      (row) => row.user_id !== rAssignRowData?.id
    );
    setStaffData({ ...staffData, assigned_staff: deleteRow });
    try {
      var res = await updateUser(staffData?.id, { assigned_staff: deleteRow });
      getShareToStaff();
      if (res?.status) enqueueSnackbar(res?.data?.message);
    } catch (error) {
      console.error(error);
      reset();
      setError("afterSubmit", { ...error, message: error.message });
    }
  };

  const handleSwitchToggle = (currentValue) => {
    return currentValue === "true" ? "false" : "true";
  };

  return (
    <>
      {/* Table to show staff list */}
      <TableRow hover selected={selected}>
        <TableCell>
          <Stack alignItems="left" spacing={2}>
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.6,
                ml: 2,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
              }}
            >
              {row.id} &nbsp;
              <Iconify
                style={{ verticalAlign: "sub" }}
                icon="material-symbols:content-copy-outline"
                onClick={() => {
                  handleCopy();
                }}
              />
            </Typography>
            <Typography>
              <Label
                variant="soft"
                color={row?.is_active ? "success" : "error"}
                sx={{ textTransform: "capitalize" }}
              >
                {row?.is_active ? "Online" : "Offline"}
              </Label>
            </Typography>
          </Stack>
        </TableCell>
        {/* <TableCell align="auto">
          <Label
            variant="soft"
            color={row?.is_active ? "success" : "error"}
            sx={{ textTransform: "capitalize" }}
          >
            {row?.is_active ? "Online" : "Offline"}
          </Label>
        </TableCell> */}
        <TableCell>
          <Stack alignItems="center" spacing={2} width="10%">
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.6,
                ml: 58,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
                marginLeft: "58px !important",
              }}
            >
              {row?.nameEnglish}
            </Typography>
            <Typography style={{ marginLeft: "80px" }}>
              {" "}
              {`(${newRow?.totalAccount})`}
              {row?.app_version === currentStaffAppVersion ? (
                <>
                  <span className="text-danger">Latest</span>
                  <Iconify className="star text-danger" icon="eva:star-fill" />-
                </>
              ) : (
                ""
              )}
              (
              <span
                className={`${
                  row?.app_version ? "text-primary" : "text-danger"
                }`}
              >
                {row?.app_version ? row?.app_version : "App Inactive"}
              </span>
              )
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                ml: 58,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
                marginLeft: "58px !important",
              }}
            >
              {row?.offlice_name}
            </Typography>
          </Stack>
        </TableCell>
        {/* <TableCell>
          <Stack direction="row" alignItems="center" spacing={2} width="10%">
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.6,
                ml: 2,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
              }}
            >
              {row?.offlice_name}
            </Typography>
          </Stack>
        </TableCell> */}

        <TableCell>
          <Stack alignItems="center" spacing={2}>
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.6,
                ml: 2,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
              }}
            >
              {row?.email}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.6,
                ml: 2,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
              }}
            >
              {maskNumber(row.mobile)}
            </Typography>
          </Stack>
        </TableCell>

        {/* <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.6,
                ml: 2,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
              }}
            >
              {maskNumber(row.mobile)}
            </Typography>
          </Stack>
        </TableCell> */}
        {contxt?.user?.sections?.staff?.update ? (
          <TableCell align="left">
            <Stack direction="row" justifyContent="end">
              <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
                Public
              </Typography>{" "}
              <Switch
                checked={row?.public_view ? 1 : 0}
                size="small"
                onChange={() => {
                  onStatus({ public_view: row?.public_view ? 0 : 1 });
                  setNewRow({
                    ...newRow,
                    public_view: newRow.public_view ? 0 : 1,
                  });
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="end">
              <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
                Masking
              </Typography>
              <Switch
                checked={row?.isNumberMaskedOn ? 1 : 0}
                size="small"
                onChange={() => {
                  handleMasking(row?.id);
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="end">
              <div
                className="mb-1 pe-3 pt-2"
                onClick={(e) => {
                  handleDoubleTick(row, row?.id);
                }}
              >
                <span className="fs-12 DesignationBtn Bg-red">Double Tick</span>
              </div>
              {/* <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
                Double Tick
              </Typography>
              <Switch
                checked={row?.double_tick_access_staff_status ? 1 : 0}
                size="small"
                onChange={() => {
                  if (!row?.double_tick_access_staff_status) {
                    handleOpenDoubleTick();
                  } else {
                    onDobleTickAssign("", false);
                  }
                }}
              /> */}
            </Stack>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
        {/* <TableCell align="center">
          <Stack direction="row">
            <Switch
              checked={row?.isNumberMaskedOn ? 1 : 0}
              size="small"
              onChange={() => {
                handleMasking(row?.id);
              }}
            />
          </Stack>
        </TableCell> */}
        {contxt?.user?.sections?.staff?.update && (
          <TableCell width="10%">
            <Stack alignItems="center" spacing={2}>
              <div
                className="noteBtn "
                style={{ marginTop: 5 }}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/mystaff/edit/${row?.id}`);
                }}
              >
                <Badge badgeContent={"Open"} color="primary"></Badge>
              </div>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowUpdate(!showUpdate);
                  setStaffData(row);
                  goToTop();
                }}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                className="mt-1"
                onClick={() => {
                  setShowPassword(!showPassword);
                  setStaffData(row);
                  goToTop();
                }}
                sx={{ whiteSpace: "nowrap" }}
              >
                Update Password
              </Button>
            </Stack>
          </TableCell>
        )}

        {contxt?.user?.sections?.staff?.delete && (
          <TableCell>
            <Button
              variant="contained"
              size="small"
              style={{ padding: "20px" }}
              onClick={() => {
                handleOpenDelete();
                setStaffData(row);
              }}
            >
              Delete
            </Button>
          </TableCell>
        )}

        <TableCell className="text-center">
          <Stack spacing={2}>
            <div>
              <div
                className="mb-1"
                onClick={(e) => {
                  handleStaffAppPermissionSections(row, row?.id);
                }}
              >
                <span className="fs-12 DesignationBtn Bg-red">
                  Staff App Task
                </span>
              </div>
              {/* <div
                className="mb-1"
                onClick={(e) => {
                  handleClose();
                  setStaffData(row);
                  let vars = sections?.map((i) => {
                    i.checked = row?.permissions?.includes(i.value);
                    return i;
                  });
                  setStaffPermission([...vars]);
                }}
              >
                <span className="fs-12 DesignationBtn Bg-red">
                  Staff App Task
                </span>
              </div> */}
              <div>
                <div
                  onClick={(e) => {
                    handleCloseForRole();
                    setStaffData(row);
                    let vars = specialSections?.map((i) => {
                      i.checked = row?.special_section?.includes(i.value);
                      return i;
                    });
                    setSpecialPermission([...vars]);
                  }}
                >
                  <span className="fs-12 DesignationBtn">
                    Master Admin Task
                  </span>
                </div>
              </div>
            </div>
          </Stack>
        </TableCell>

        <TableCell className="text-center">
          <Button
            variant="contained"
            size="small"
            style={{ background: "#333" }}
            onClick={handleCloseForTeam}
          >
            Add Team
          </Button>
          <div
            className="mb-1"
            onClick={(e) => {
              handleCloseForAssign();
              setStaffData(row);
              setRowId(row?.id);
              let vars = specialSections?.map((i) => {
                i.checked = row?.special_section?.includes(i.value);
                return i;
              });
              setSpecialPermission([...vars]);
            }}
          >
            <span className="fs-12 DesignationBtn Bg-red">
              Assign Management Team
            </span>
          </div>
          <div
            onClick={(e) => {
              handleCloseForSpecial();
              setStaffData(row);
              let vars = specialSections?.map((i) => {
                i.checked = row?.special_section?.includes(i.value);
                return i;
              });
              setSpecialPermission([...vars]);
            }}
          >
            <span className="fs-12 DesignationBtn">Give Designation</span>
            {/* <Badge badgeContent={"Special"} color="secondary"></Badge> */}
          </div>
        </TableCell>
      </TableRow>
      {row?.created_at && (
        <TableRow>
          <TableCell colSpan="12" className="boderNone">
            <Typography
              variant="subtitle2"
              sx={{
                mt: 0.6,
                display: "block",
                color: "text.secondary",
                whiteSpace: "noWrap",
                fontSize: "12px",
              }}
            >
              Created By :-{" "}
              <span style={{ fontWeight: "400", color: "blue" }}>
                {row?.createdByStaff?.name?.english}
              </span>{" "}
              <span style={{ fontWeight: "400", color: "blue" }}>
                {row?.created_at
                  ? moment(row?.created_at).format("DD/MM/YY , hh:mm")
                  : null}
              </span>
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {row?.PasswordHistory.map((passwordHis) => {
        return (
          <TableRow>
            <TableCell colSpan="12" className="boderNone">
              <Typography
                variant="subtitle2"
                sx={{
                  mt: 0.6,
                  display: "block",
                  color: "text.secondary",
                  whiteSpace: "noWrap",
                  fontSize: "12px",
                }}
              >
                {" "}
                Password Updated By :-
                <span style={{ fontWeight: "400", color: "blue" }}>
                  {passwordHis?.StaffNamePass?.name?.english}
                </span>{" "}
                <span style={{ fontWeight: "400", color: "blue" }}>
                  {passwordHis?.updated_at
                    ? moment(passwordHis?.updated_at).format("DD/MM/YY , hh:mm")
                    : null}
                </span>
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow>
        <TableCell colSpan="12" className="boderNone">
          <Typography
            variant="subtitle2"
            sx={{
              mt: 0.6,
              display: "block",
              color: "text.secondary",
              whiteSpace: "noWrap",
              fontSize: "12px",
            }}
          >
            Team Assign By :-{" "}
            <span style={{ fontWeight: "400", color: "blue" }}>
              Riya-dev [1-04-24 ,5:50pm]
            </span>{" "}
            <span style={{ fontWeight: "400", color: "red" }}>
              Assigned to:-{" "}
              <span style={{ fontWeight: "400", color: "blue" }}>
                Rahul-dev [1-04-24 ,5:50pm]
              </span>
            </span>
          </Typography>
        </TableCell>
      </TableRow>
      {row?.role_id ? (
        <TableRow>
          <TableCell colSpan="12" className="boderNone">
            <Typography variant="caption">
              <b style={{ color: "red" }}>Master Admin Task : </b>{" "}
              {row?.RolesDetailsName}
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        ""
      )}
      {row?.permissions && (
        <TableRow>
          <TableCell colSpan="12" className="boderNone">
            <Typography variant="caption">
              <b>Sections : </b>
              {row?.permissions?.replaceAll(",", " | ").replaceAll("_", " ")}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {row?.response_data_section && (
        <TableRow>
          <TableCell colSpan="12" className="boderNone">
            <Typography variant="caption">
              <b>Sub Sections : </b>
              {row?.response_data_section}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell colSpan="12" className="boderNone">
          <Typography variant="caption">
            <b>Team : </b>xyz...
          </Typography>
        </TableCell>
      </TableRow>

      {row?.response_data_section && (
        <TableRow>
          <TableCell colSpan="12" className="boderNone">
            <Typography variant="caption">
              <b>Sub Sections : </b>
              {row?.response_data_section}
            </Typography>
          </TableCell>
        </TableRow>
      )}

      {row?.special_section && (
        <TableRow>
          <TableCell colSpan="12">
            <Typography variant="caption">
              <b>Special Sections : </b>
              {row?.special_section
                ?.replaceAll(",", " | ")
                .replaceAll("_", " ")}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {/* Staff permission Modal */}
      <Dialog
        open={staffPermissionModalTest}
        onClose={handleClose}
        fullWidth
        maxWidth={"lg"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleStaffAppPermissionModal();
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          title={"Assign Sections To Staff"}
          sx={{ textAlign: "center" }}
        />
        <DialogContent>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmitStaffAppSection)}
          >
            <Stack alignItems="center">
              <FormGroup>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {staffAppPermissions?.map(
                      (section, i) =>
                        section?.section === "Universal Online Search" && (
                          <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            className="text-center fw-bold"
                          >
                            <div className="me-3">
                              Universal Online Search :
                            </div>
                            <div>
                              <FormControlLabel
                                key={"staff_data_entry"}
                                name="permissions"
                                control={
                                  <Checkbox
                                    checked={section["Search by Id"]}
                                    onChange={(e) => {
                                      setStaffAppPermissions(
                                        (prevPermissions) => {
                                          let newPermissions = [
                                            ...prevPermissions,
                                          ];
                                          newPermissions[i]["Search by Id"] =
                                            !newPermissions[i]["Search by Id"];
                                          return newPermissions;
                                        }
                                      );
                                    }}
                                  />
                                }
                                label={"Search by Id"}
                                variant="h4"
                                sx={{ textTransform: "capitalize" }}
                              />
                              <FormControlLabel
                                key={"staff_data_entry"}
                                name="permissions"
                                control={
                                  <Checkbox
                                    checked={section["Search by Mobile"]}
                                    onChange={(e) => {
                                      setStaffAppPermissions(
                                        (prevPermissions) => {
                                          let newPermissions = [
                                            ...prevPermissions,
                                          ];
                                          newPermissions[i][
                                            "Search by Mobile"
                                          ] =
                                            !newPermissions[i][
                                              "Search by Mobile"
                                            ];
                                          return newPermissions;
                                        }
                                      );
                                    }}
                                  />
                                }
                                label={"Search by Mobile"}
                                variant="h4"
                                sx={{ textTransform: "capitalize" }}
                              />
                            </div>
                          </Stack>
                        )
                    )}
                  </Grid>
                  {staffAppPermissions &&
                    staffAppPermissions?.map(
                      (section, i) =>
                        section?.section !== "Response Data" &&
                        section?.section !== "Universal Online Search" &&
                        section?.section !== "Hindu Response Data" && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Stack direction={"row"}>
                              <div className="row">
                                <div className="col">{section?.section}</div>
                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_calling"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={section?.Calling}
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i].Calling =
                                                !newPermissions[i].Calling;
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Calling"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>

                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_data_entry"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={section["Data entry"]}
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i]["Data entry"] =
                                                !newPermissions[i][
                                                  "Data entry"
                                                ];
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Data entry"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>

                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_data_entry"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={section["Search by Id"]}
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i][
                                                "Search by Id"
                                              ] =
                                                !newPermissions[i][
                                                  "Search by Id"
                                                ];
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Search by Id"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>

                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_data_entry"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={section["Search by Mobile"]}
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i][
                                                "Search by Mobile"
                                              ] =
                                                !newPermissions[i][
                                                  "Search by Mobile"
                                                ];
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Search by Mobile"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>

                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_entry_report"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={section["Entry report"]}
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i][
                                                "Entry report"
                                              ] =
                                                !newPermissions[i][
                                                  "Entry report"
                                                ];
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Entry report"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>

                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_generated_pay_link_history"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={
                                          section["Generated pay link history"]
                                        }
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i][
                                                "Generated pay link history"
                                              ] =
                                                !newPermissions[i][
                                                  "Generated pay link history"
                                                ];
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Generated pay link history"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>

                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_self_assign"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={section["Self Assign"]}
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i]["Self Assign"] =
                                                !newPermissions[i][
                                                  "Self Assign"
                                                ];
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Self Assign"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>

                                <div className="col">
                                  <FormControlLabel
                                    key={"staff_self_registration_report"}
                                    name="permissions"
                                    control={
                                      <Checkbox
                                        checked={
                                          section["Self registeration report"]
                                        }
                                        onChange={(e) => {
                                          setStaffAppPermissions(
                                            (prevPermissions) => {
                                              let newPermissions = [
                                                ...prevPermissions,
                                              ];
                                              newPermissions[i][
                                                "Self registeration report"
                                              ] =
                                                !newPermissions[i][
                                                  "Self registeration report"
                                                ];
                                              return newPermissions;
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    label={"Self registeration report"}
                                    variant="h4"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </div>
                              </div>
                            </Stack>
                          </Grid>
                        )
                    )}
                </Grid>
              </FormGroup>
            </Stack>
            <div style={{ textAlign: "center" }}>
              <LoadingButton
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  my: 2,
                  bgcolor: "text.primary",
                  width: "20%",
                  justifyContent: "center",
                  typography: "body1",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </FormProvider>
        </DialogContent>
      </Dialog>

      <Dialog
        open={staffPermissionModal}
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleClose();
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          title={"Assign Sections To Staff"}
          sx={{ textAlign: "center" }}
        />
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems="center">
              <FormGroup>
                <Grid container spacing={1}>
                  {staffPermission &&
                    staffPermission?.map((section, i) => (
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControlLabel
                          key={section.label}
                          name="permissions"
                          control={
                            <Checkbox
                              checked={section?.checked}
                              onChange={(e) => {
                                staffPermission[i].checked =
                                  !staffPermission[i].checked;
                                setStaffPermission([...staffPermission]);
                                if (section?.label === "Response Data") {
                                  handleOpenResponseModal();
                                }
                              }}
                            />
                          }
                          label={section.label}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </FormGroup>
            </Stack>
            <div style={{ textAlign: "center" }}>
              <LoadingButton
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  my: 2,
                  bgcolor: "text.primary",
                  width: "20%",
                  justifyContent: "center",
                  typography: "body1",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* Staff add team Modal */}
      <Dialog
        open={addTeamModal}
        onClose={handleCloseForTeam}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleCloseForTeam();
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          // title={"Assign Sections To Staff"}
          sx={{ textAlign: "center" }}
        />
        <DialogContent>
          <FormProvider>
            <Stack alignItems="center">
              <FormGroup>
                <Grid container spacing={1}>
                  {staffName &&
                    staffName?.map((section, i) => (
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControlLabel
                          key={section.nameEnglish}
                          name="permissions"
                          control={
                            <Checkbox
                              checked={section?.checked}
                              // onChange={(e) => {
                              //   staffPermission[i].checked =
                              //     !staffPermission[i].checked;
                              //   setStaffPermission([...staffPermission]);
                              // }}
                            />
                          }
                          label={section.nameEnglish}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </FormGroup>
            </Stack>
            <div style={{ textAlign: "center" }}>
              <LoadingButton
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  my: 2,
                  bgcolor: "text.primary",
                  width: "20%",
                  justifyContent: "center",
                  typography: "body1",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* Special Sections Model */}
      <Dialog
        open={isOpen}
        onClose={handleCloseForSpecial}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleCloseForSpecial();
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          title={"Assign Special Sections To Staff"}
          sx={{ textAlign: "center" }}
        />
        <DialogContent>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmitSpecial)}
          >
            <Stack alignItems="center">
              <FormGroup>
                <Grid container spacing={1}>
                  {specialPermission &&
                    specialPermission?.map((section, i) => (
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControlLabel
                          key={section.label}
                          name="permissions"
                          control={
                            <Checkbox
                              checked={section?.checked}
                              onChange={(e) => {
                                // var data = specialPermission.map(val => {
                                //     val.checked = false
                                //     return val;
                                // })
                                specialPermission[i].checked =
                                  !specialPermission[i].checked;
                                setSpecialPermission([...specialPermission]);
                              }}
                            />
                          }
                          label={section.label}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </FormGroup>
            </Stack>
            <div style={{ textAlign: "center" }}>
              <LoadingButton
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  my: 2,
                  bgcolor: "text.primary",
                  width: "20%",
                  justifyContent: "center",
                  typography: "body1",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* Assign Staff for Special Sections */}
      <Dialog
        Dialog
        open={isOpenAssign}
        onClose={handleCloseForAssign}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleCloseForAssign();
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          title={"Assign Staff for Special Sections"}
          sx={{ textAlign: "center" }}
        />
        <DialogContent>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmitAssign)}
          >
            <Grid container spacing={3} sx={{ mt: 0.1 }}>
              <Grid item lg={4} xs={12} md={4}>
                <RHFTextField
                  native
                  fullWidth
                  select
                  size="small"
                  name="special_section"
                  label="Special Sections"
                  // onChange={(e) => { setValue('number_type', e.target.value); }}
                  sx={{
                    maxWidth: { sm: "auto" },
                    ml: 2,
                    textTransform: "capitalize",
                  }}
                >
                  {specialSections?.map((i) => (
                    <MenuItem
                      key={i?.id}
                      value={i.value}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Grid>
              <Grid item lg={4} xs={12} md={4}>
                <RHFTextField
                  native
                  fullWidth
                  select
                  size="small"
                  name="special_staff"
                  label="Special Staff"
                  // onChange={(e) => { setValue('number_type', e.target.value); }}
                  sx={{
                    maxWidth: { sm: "auto" },
                    ml: 2,
                    textTransform: "capitalize",
                  }}
                >
                  {specialStaff?.map((i) => (
                    <MenuItem
                      key={i?.id}
                      value={i}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {i?.nameEnglish}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Grid>
              <Grid item lg={4} md={4} xs={6} sm={4}>
                <Stack spacing={2} direction="row" alignItems="center">
                  <Button
                    variant="contained"
                    color="inherit"
                    type="submit"
                    size="small"
                    sx={{ mt: 1 }}
                  >
                    Add
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>

          <TableContainer sx={{ mt: 3, overflow: "unset" }}>
            <Scrollbar>
              <Table>
                <TableHeadCustom headLabel={TABLE_HEAD} />
                <TableBody>
                  {userRows}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
            {!assignData?.users[0]?.assigned_staff?.length && !isNotFound && (
              <Box display="flex" justifyContent="center" alignItems="center">
                {/* <LoadingScreenSmall /> */}
              </Box>
            )}
          </TableContainer>
          <TablePaginationCustom
            onChangePageButton={onChangePageButton}
            count={assignData?.users[0]?.assigned_staff?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </DialogContent>
      </Dialog>

      {/* response data popup Model */}
      <Dialog
        Dialog
        open={responseModalOpen}
        onClose={handleCloseResponseModal}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleCloseResponseModal();
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          title={"Response Data Modal"}
          sx={{ textAlign: "center" }}
        />
        <DialogContent>
          <FormProvider onSubmit={handleSubmit(onSubmitResponseAssign)}>
            <Stack alignItems="center">
              <FormGroup>
                {selectedResponseData.map((response, i) => (
                  <FormControlLabel
                    key={response.value}
                    control={
                      <Checkbox
                        checked={response.checked}
                        onChange={(e) => {
                          const updatedResponseData = [...selectedResponseData];
                          updatedResponseData[i].checked =
                            !updatedResponseData[i].checked;
                          setSelectedResponseData(updatedResponseData);
                        }}
                      />
                    }
                    label={response.label}
                  />
                ))}
              </FormGroup>
            </Stack>
            <div style={{ textAlign: "center" }}>
              <LoadingButton
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  my: 2,
                  bgcolor: "text.primary",
                  width: "20%",
                  justifyContent: "center",
                  typography: "body1",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </FormProvider>
        </DialogContent>
      </Dialog>

      {/* Assign Role */}
      <Dialog
        Dialog
        open={openRole}
        onClose={handleCloseForRole}
        fullWidth
        maxWidth={"md"}
      >
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton
                color="error"
                className="CloseBtn"
                onClick={() => {
                  handleCloseForRole();
                }}
              >
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          title={"Assign Role"}
          sx={{ textAlign: "center" }}
        />
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onRoles)}>
            <Stack alignItems="center">
              <FormGroup>
                <Grid container spacing={1}>
                  {roles &&
                    roles?.map((role, i) => (
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControlLabel
                          key={role.id}
                          name="role_id"
                          // control={<Checkbox checked={role.id === roleData?.id} onChange={() => {
                          //     setRoles(role)
                          // }} />}
                          control={
                            <Checkbox
                              checked={selectedRoleIds.includes(role.id)}
                              onChange={() => handleRoleToggle(role.id)}
                            />
                          }
                          label={role.role}
                          variant="h4"
                          sx={{ textTransform: "capitalize" }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </FormGroup>
            </Stack>
            <div style={{ textAlign: "center" }}>
              <LoadingButton
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  my: 2,
                  bgcolor: "text.primary",
                  width: "20%",
                  justifyContent: "center",
                  typography: "body1",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Submit
              </LoadingButton>
            </div>
          </FormProvider>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        className="deleteModal"
        open={openDelete}
        onClose={handleOpenDelete}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <div>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleOpenDelete}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onDelete();
                handleOpenDelete();
              }}
            >
              Delete
            </Button>
          </div>
        }
      />
      {/* remove assign staff for sections Model */}
      <ConfirmDialog
        className="deleteModal"
        open={openRemove}
        onClose={handleOpenRemove}
        title="Delete"
        content="Are you sure want to Remove?"
        action={
          <div>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleOpenRemove}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onRemoveAssign();
                handleOpenRemove();
              }}
            >
              Delete
            </Button>
          </div>
        }
      />
      <Dialog open={openDoubleTick} onClose={handleOpenDoubleTick}>
        <DialogTitle>Double Tick Crm Number Type</DialogTitle>

        <DialogContent>
          <Box>
            <FormControl>
              {/* First Row */}
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="body1">7583831008</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={
                      doubletickUpdate?.double_tick_crm_one_status === "true"
                    }
                    onChange={() => {
                      setDoubletickUpdate({
                        ...doubletickUpdate,
                        double_tick_crm_one_status: handleSwitchToggle(
                          doubletickUpdate?.double_tick_crm_one_status
                        ),
                      });
                    }}
                    color="primary"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" size="small">
                    <TextField
                      select
                      disabled
                      fullWidth
                      label="Staff number"
                      sx={{ width: 200 }}
                      value={row?.StaffMobileNum[0]?.number_one}
                      onChange={(e) => {
                        setDoubletickUpdate({
                          ...doubletickUpdate,
                          staff_num_access_at_double_tick_one: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={row?.StaffMobileNum[0]?.number_one}>
                        {row?.StaffMobileNum[0]?.number_one}
                      </MenuItem>
                      <MenuItem value={row?.StaffMobileNum[0]?.number_two}>
                        {row?.StaffMobileNum[0]?.number_two}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Second Row */}
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="body1">7583831009</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={
                      doubletickUpdate?.double_tick_crm_two_status === "true" ||
                      doubletickUpdate?.double_tick_crm_two_status === true
                    }
                    onChange={() => {
                      setDoubletickUpdate({
                        ...doubletickUpdate,
                        double_tick_crm_two_status: handleSwitchToggle(
                          doubletickUpdate?.double_tick_crm_two_status
                        ),
                      });
                    }}
                    color="primary"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" size="small">
                    <TextField
                      select
                      disabled
                      fullWidth
                      label="Staff number"
                      sx={{ width: 200 }}
                      value={
                        // doubletickUpdate?.staff_num_access_at_double_tick_two
                        row?.StaffMobileNum[0]?.number_one
                      }
                      onChange={(e) => {
                        setDoubletickUpdate({
                          ...doubletickUpdate,
                          staff_num_access_at_double_tick_two: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={row?.StaffMobileNum[0]?.number_one}>
                        {row?.StaffMobileNum[0]?.number_one}
                      </MenuItem>
                      <MenuItem value={row?.StaffMobileNum[0]?.number_two}>
                        {row?.StaffMobileNum[0]?.number_two}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>

              {/* third number for hind */}
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="body1">9516520150</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={
                      doubletickUpdate?.double_tick_crm_three_status ===
                        "true" ||
                      doubletickUpdate?.double_tick_crm_three_status === true
                    }
                    onChange={() => {
                      setDoubletickUpdate({
                        ...doubletickUpdate,
                        double_tick_crm_three_status: handleSwitchToggle(
                          doubletickUpdate?.double_tick_crm_three_status
                        ),
                      });
                    }}
                    color="primary"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" size="small">
                    <TextField
                      select
                      disabled
                      fullWidth
                      label="Staff number"
                      sx={{ width: 200 }}
                      value={
                        // doubletickUpdate?.staff_num_access_at_double_tick_two
                        row?.StaffMobileNum[0]?.number_one
                      }
                      onChange={(e) => {
                        setDoubletickUpdate({
                          ...doubletickUpdate,
                          staff_num_access_at_double_tick_three: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={row?.StaffMobileNum[0]?.number_one}>
                        {row?.StaffMobileNum[0]?.number_one}
                      </MenuItem>
                      <MenuItem value={row?.StaffMobileNum[0]?.number_two}>
                        {row?.StaffMobileNum[0]?.number_two}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleOpenDoubleTick}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDobleTickAssign(doubletickUpdate);
              handleOpenDoubleTick();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
